/*==============================================================
// wordpress REST API 関連
==============================================================*/

// ホッとStoryの最新リンク先取得
function wpNewsStory() {
    var wp_url_story = '/wp-json/wp/v2/story/?_embed';
    var wp_html_story_title = '';
    var wp_nothumbnail_story = './assets/img/cms/story/no-image.png';

    $.ajax({
        type: 'GET',
        url: wp_url_story,
        dataType: 'json',
        data: {
            per_page: 1,
        }

    }).done(function (json) {

        //記事の件数分イテレートする
        $.each(json, function (i, item) {

            // コンソールに表示（削除）
            // console.log(item);


            //ブログのURL////////////////////////////////////////////
            var wp_link_story = item.link;
            var target_link_id = 'js-story-href';
            const html_link_target_id = document.getElementById(target_link_id);

            html_link_target_id.setAttribute('href', wp_link_story);

            //最新記事のサムネイル//////////////////////////////////////
            var wp_thumbnail_story
            if (item['_embedded']['wp:featuredmedia']) {
                wp_thumbnail_story = item['_embedded']['wp:featuredmedia'][0]['media_details']['sizes']['full']['source_url']
            } else {
                wp_thumbnail_story = wp_nothumbnail_story
            }

            var target_thumbnail_id = 'js-story-src';
            const html_thumbnail_target_id = document.getElementById(target_thumbnail_id);

            html_thumbnail_target_id.setAttribute('src', wp_thumbnail_story);

            //最新記事のタイトル///////////////////////////////////////
            var wp_title_story = item.title.rendered;

            wp_html_story_title += wp_title_story;
        });

        //最新記事のタイトルを取得
        $('.js-story-title').append(wp_html_story_title)

    }).fail(function (json) {
        console.error('記事取得に失敗しました。')
    });
}


// event一覧取得 => 相談会、イベント＆相談会
function wpNewsEvent() {
    var wp_url_event = '/wp-json/wp/v2/event/?_embed';
    var wp_html_event = '';
    var wp_nothumbnail_event = './assets/img/cms/event/no-image.png';

    $.ajax({
        type: 'GET',
        url: wp_url_event,
        dataType: 'json',
        data: {
            per_page: 3,
        }

    }).done(function (json) {

        //記事の件数分イテレートする
        $.each(json, function (i, item) {

            // コンソールに表示（削除）
            console.log(item);

            //ブログのタイトル
            var str = item.title.rendered

            if(str.length > 50 ){
                var wp_title_event = str.substring(0, 50) + '...';
            }else {
                var wp_title_event = str;
            }

            //ブログのURL
            var wp_link_event = item.link;

            //ブログのターム名、サムネイル画像のURL
            var wp_category_event
            //var wp_category_event = item['_embedded']['wp:term'][0][0]['id']
            if (item['_embedded']['wp:term'][0][0]['slug'] === 'category-event-reform') {
                // リフォームお気軽相談会
                wp_category_event = '無料相談会'
            } else if (item['_embedded']['wp:term'][0][0]['slug'] === 'category-event-seminar') {
                // 断熱リフォーム相談会
                wp_category_event = '無料セミナー'
            } else {
                // イベント＆相談会
                wp_category_event = 'イベント'
            }

            //サムネイル画像のURL
            var wp_thumbnail_event
            if (item['_embedded']['wp:featuredmedia']) {
                wp_thumbnail_event = item['_embedded']['wp:featuredmedia'][0]['media_details']['sizes']['full']['source_url']
            } else (wp_thumbnail_event = wp_nothumbnail_event)

            // li以下
            wp_html_event += '<li class="item"><a class="link" href="' + wp_link_event + '">';
            wp_html_event += '<div class="img"><img src="' + wp_thumbnail_event + '" alt="' + wp_title_event + '"></div>';
            wp_html_event += '<div class="message">';
            wp_html_event += '<h3 class="title">' + wp_title_event + '</h3>';
            wp_html_event += '<ul class="tag-list"><li class="tag">' + wp_category_event + '</li></ul>';
            wp_html_event += '</div>';
            wp_html_event += '</a></li>';
        });

        //整形した記事の情報をページに追加する
        $('.js-newslist-event').append(wp_html_event)

    }).fail(function (json) {
        console.error('記事取得に失敗しました。')
    });
}

// topics一覧取得 => お知らせ＆最新情報
function wpNewsTopics() {
    var wp_url_topics = '/wp-json/wp/v2/topics/?_embed';
    var wp_nothumbnail_topics = './assets/img/cms/topics/no-image.png';
    var wp_html_topics = '';

    $.ajax({
        type: 'GET',
        url: wp_url_topics,
        dataType: 'json',
        data: {
            per_page: 11,
        }

    }).done(function (json) {

        //記事の件数分イテレートする
        $.each(json, function (i, item) {

            // コンソールに表示（削除）
            console.log(item);

            //ブログのタイトル
            var wp_title_topics = item.title.rendered;

            //ブログのURL
            var wp_link_topics = item.link;

            //ブログの日にち
            var wp_date_data = new Date(item.date);
            var wp_year = wp_date_data.getFullYear();
            var wp_month = ('0' + (wp_date_data.getMonth() + 1)).slice(-2);
            var wp_day = ('0' + wp_date_data.getDate()).slice(-2);
            var wp_date_topics = wp_year + '.' + wp_month + '.' + wp_day;

            //サムネイル画像のURL
            var wp_thumbnail_topics
            if (item['_embedded']['wp:featuredmedia']) {
                wp_thumbnail_topics = item['_embedded']['wp:featuredmedia'][0]['media_details']['sizes']['full']['source_url']
            } else (wp_thumbnail_topics = wp_nothumbnail_topics)

            // li以下
            wp_html_topics += '<li class="swiper-slide topics-item"><a class="link" href="' + wp_link_topics + '">';
            wp_html_topics += '<div class="img"><img src="' + wp_thumbnail_topics + '" alt="' + wp_title_topics + '"></div>';
            wp_html_topics += '<div class="message">';
            wp_html_topics += '<p class="date">' + wp_date_topics + '</p>';
            wp_html_topics += '<p class="txt">' + wp_title_topics + '</p>';
            wp_html_topics += '</div>';
            wp_html_topics += '</a></li>';
        });

        //整形した記事の情報をページに追加する
        $('.js-newslist-topics').append(wp_html_topics)

    }).fail(function (json) {
        console.error('記事取得に失敗しました。')
    });
}


wpNewsStory()

wpNewsEvent()
wpNewsTopics()
